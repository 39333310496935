import React, { useEffect } from 'react';
import PurchaseRegistration from 'src/Components/PurchaseRegistration';
import MainPageTemplateCustomLayout from './Templates/MainPageTemplateCustomLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { handleLogout } from '../Components/LogoutUtils';

import borgLogo from "../assets/img/Borg-logo.png";
import kepleraLogo from "../assets/img/Keplera-logo.png";

const borgFeatures = (
  <div>
    <h3>Key Features and Benefits</h3>
    <ul>
      <li>Rendezvous Proximity Ops (RPO)</li>
      <li>Frames of References</li>
      <li>Fuel Awareness and Cooldown Periods</li>
      <li>LEO/GEO Scenarios and Sandbox Mode</li>
    </ul>
  </div>
);

const borgWhatsIncluded = (
  <div>
    <h3>What's Included</h3>
    <ul>
      <li>Annual subscription with reduced renewal fees</li>
      <li>Free trials and introductory pricing</li>
      <li>Web-based and desktop applications</li>
      <li>In-game tutorials and help</li>
      <li>Email tech support and online FAQs</li>
    </ul>
  </div>
);

const kepleraFeatures = (
  <div>
    <h3>Key Features and Benefits</h3>
    <ul>
      <li>Space 101: 20+ Lessons</li>
      <li>Frames of References (Inertial & RIC)</li>
      <li>Rendezvous & Proximity Operations (RPO)</li>
      <li>Objectives-Based Scenarios</li>
    </ul>
  </div>
);

const kepleraWhatsIncluded = (
  <div>
    <h3>What's Included</h3>
    <ul>
      <li>Annual subscription with reduced renewal fees</li>
      <li>Classroom leaderboards and management</li>
      <li>Web-based and desktop applications</li>
      <li>In-game tutorials and help</li>
      <li>Email tech support and online FAQs</li>
    </ul>
  </div>
);

const colorAuriaYellow= "#FFBC46" //TODO - replace with a global value from style sheet

const isKepleraVersion = process.env.REACT_APP_PRODUCT_NAME === "keplera";

const productLogo = isKepleraVersion ? kepleraLogo : borgLogo;
const features = isKepleraVersion ? kepleraFeatures : borgFeatures;
const whatsIncluded = isKepleraVersion ? kepleraWhatsIncluded : borgWhatsIncluded;


type Props = {};

const RegisterPage = (props: Props) => {
  const pageType = useLocation().state as string || 'PurchaseSubscription'; //Pull in prop and use to conditionally render components or if no value found default to Purchase Subscription
  const navigate = useNavigate();

  return (
    <MainPageTemplateCustomLayout>
      <div className="main-register"
        style={{
          display: 'flex',
          flexDirection: pageType === 'Standalone' ? 'column' : undefined,
        }}>
        <div className="borg-logo-purchase-info-container" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="borg-logo-mediumsize">
            <img alt="logo" src={productLogo} />
          </div>
          {/* Purchase Info not displayed if running Standalone Server */}
          {pageType !== 'Standalone' && (<div className="purchase-info">
            {pageType === 'PurchaseSubscription' && (<div>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                <Typography variant="h1" sx={{ color: colorAuriaYellow, fontSize: '5rem', mr: { xs: 1, md: 2 } }}>
                  $250
                </Typography>
                <Box>
                  <Typography variant="body1" sx={{ color: 'white' }}>
                    Billed Monthly
                  </Typography>
                  <Box sx={{ width: '100%', height: '2px', backgroundColor: colorAuriaYellow, mt: 1, mb: 1 }} />
                  <Typography variant="body1" sx={{ color: 'white' }}>
                    Cancel Anytime
                  </Typography>
                </Box>
              </Box>
            </div>)}
            {features}
            {whatsIncluded}
          </div>)}
        </div>
        <PurchaseRegistration pageType={pageType} />
      </div>
    </MainPageTemplateCustomLayout>
  );
};

export default RegisterPage;
