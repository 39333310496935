import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, styled,
  Switch, Typography, Chip, TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import {
  addGroup, getGroups, getGroupsPerOrganization, getOrganization, getSession, removeGroup,
  startSession, stopSession
} from '../../Services/BorgAPIClient';
import moment from 'moment';
import ChangeConfirmationDialog from '../../Components/ChangeConfirmationDialog';
import { LoggedInUserContext } from '../../Components/Admin/AdminPageContainer'; // Import context
import { Roles } from 'src/BorgEnums';
import CreateGroupModal from 'src/Components/CreateGroupModal';

type Group = {
  id: string;
  name: string;
  password: string;
  ownerId: string;
  ownerOrganizationId: string;
  caption: string;
  memberIds: string[];
  activeSessionId: string;
  createdDate: Date;
  Competitions: {
    id: string,
    name: string,
    startDate: Date;
    endDate: Date | null;
    type: number | null,
  };
};

const CustomTableHead = styled(TableHead)({
  backgroundColor: 'black',
  '& th': {
    color: 'white',
    cursor: 'pointer',
  },
});

const GroupsAdminPage: React.FC = () => {
  const [currentGroupList, setCurrentGroupList] = useState<Group[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState<Group | null>(null);
  const [sortBy, setSortBy] = useState<keyof Group>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [isCreateGroupOpen, setIsCreateGroupOpen] = useState(false);
  const [disableAllUsers, setDisableAllUsers] = useState(false);
  const [openEndTermDialog, setOpenEndTermDialog] = useState(false);
  const [groupToEndTerm, setGroupToEndTerm] = useState<Group | null>(null);

  const loggedInUser = useContext(LoggedInUserContext);

  const fetchGroups = useCallback(async () => {
    if (loggedInUser)  //if logged in user has been obtained
    {
      let result;
      try {
        if (loggedInUser.roles[0] == Roles.SystemAdmin) //If logged in user is system admin then return all groups
          result = await getGroups();
        else if (loggedInUser.roles[0] == Roles.OrganizationAdmin) //If organization admin return all groups in the admins organization
        {
          //Get organization id
          var loggedInUserOrganizationData = await getOrganization(loggedInUser.organization);
          result = await getGroupsPerOrganization(loggedInUserOrganizationData.data.id);
        }
        setCurrentGroupList(result.data);
        setIsLoaded(true);
      } catch (error) {
        console.error('Failed to fetch groups:', error);
      }
    }
  }, [loggedInUser]);

  const fetchSession = async () => {
    if (currentGroupList.length > 0) {
      const updatedGroups = currentGroupList.map(async (group) => {
        const response = await getSession(group.id);
        return { ...group, Competitions: response.data }; // Create a new object
      });

      // Wait for all promises to resolve before updating state
      const newGroupList = await Promise.all(updatedGroups);

      // Check if newGroupList is different from currentGroupList
      const currentGroupListJson = JSON.stringify(currentGroupList);
      const newGroupListJson = JSON.stringify(newGroupList);

      if (currentGroupListJson !== newGroupListJson) {//Only execute setCurrentGroupList if newGroup has changed
        setCurrentGroupList(newGroupList);
      }
    }
  };

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  useEffect(() => {
    fetchSession();
  }, [currentGroupList]); //After group list is obtained execute fetchSession

  const closeConfirmDeleteDialog = () => {
    setOpenDialog(false);
  };

  const toggleCreateGroupModal = () => {
    setIsCreateGroupOpen(!isCreateGroupOpen);
  }

  //Sorting functions
  const handleSort = (column: keyof Group) => {
    const isAsc = sortBy === column && sortDirection === 'asc';
    setSortBy(column);
    setSortDirection(isAsc ? 'desc' : 'asc');
  };

  const sortedGroups = [...currentGroupList].sort((a, b) => {
    const compareResult = (aVal: any, bVal: any) => {
      if (typeof aVal === 'string' && typeof bVal === 'string') {
        return aVal.localeCompare(bVal);
      }
      if (aVal < bVal) return -1;
      if (aVal > bVal) return 1;
      return 0;
    };

    const result = compareResult(a[sortBy], b[sortBy]);
    return sortDirection === 'asc' ? result : -result;
  });

  //Event Functions
  const handleCreateGroupConfirm = async (groupName: string) => {
    const fullGroupData = {
      name: groupName,
      ownerId: loggedInUser?.id,
      caption: "",
      memberIds: [],
    };

    //API Call to POST new Group
    try {
      await addGroup(fullGroupData); // Call to add group
    } catch (err: any) {
      console.error("An error occurred during registration processing.", err);
    }
    toggleCreateGroupModal(); //Close modal
    fetchGroups();  //Update table
  }

  const handleToggleSessionClick = async (group: Group) => {
    if (!group.activeSessionId) //If there is no active session, start the session
      try {
        await startSession(group);
        fetchGroups();  //Update table
      } catch (err: any) {
        console.error('Error starting session:', err)
      }
    else  //If there is an active session, open endterm dialog
    {
      setGroupToEndTerm(group);
      setOpenEndTermDialog(true);
    }
  }

  const handleEndTerm = async () => {
    if (groupToEndTerm) {
      try {
        await stopSession(groupToEndTerm.id, disableAllUsers);
        fetchGroups();  //Update table
      } catch (err: any) {
        console.error('Error stopping session:', err)
      }
      setOpenEndTermDialog(false);
    }
  }
  const handleDeleteClick = (group: Group) => {
    setGroupToDelete(group);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (groupToDelete) {
      try {
        await removeGroup(groupToDelete.id);
        await fetchGroups();
      } catch (error) {
        console.error('Failed to delete group:', error);
      }
    }
    setOpenDialog(false);
    setGroupToDelete(null);
  };

  //Misc Functions
  const calculateTimeAgo = (date: Date) => {
    if (!date) return '';

    return moment(date).fromNow();
  };

  function formatDate(date: Date) {
    if (!date) return '';
    return new Date(date).toLocaleString('en-US', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    }).replace(',', ' at');
  }

  //Custom components:
  const createGroupComponent = (
    <div>
      <Box sx={{
        marginY: 2,
        borderRadius: 1,
      }}
      >
        <Button variant="contained" color="primary"
          onClick={() => toggleCreateGroupModal()}
          startIcon={<AddIcon />} >
          Create Course
        </Button>
      </Box>
      <CreateGroupModal
        isOpen={isCreateGroupOpen}
        onClose={toggleCreateGroupModal}
        onConfirm={handleCreateGroupConfirm}
      />
    </div>
  )
  const endTermDialog = (
    <div>
      <Dialog open={openEndTermDialog} onClose={() => setOpenEndTermDialog(false)}>
        <DialogTitle>End Term</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={<Checkbox checked={disableAllUsers} onChange={(e) => setDisableAllUsers(e.target.checked)} />}
            label="Disable all users in course?" />
          <DialogContentText>Disabled users will not be deleted nor will they be removed from the gradebook for this term.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEndTermDialog(false)}>Cancel</Button>
          <Button onClick={() => handleEndTerm()} autoFocus> Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  )

  if (isLoaded && currentGroupList.length > 0) {
    return (
      <div>
        {createGroupComponent}
        <Box sx={{
          overflow: 'auto',
          borderRadius: 1,
          marginY: 2,
          bgcolor: 'primary.dark'
        }}>
          <TableContainer component={Paper}>
            <Table>
              <CustomTableHead>
                <TableRow>
                  <TableCell onClick={() => handleSort('name')}>
                    Course Name {sortBy === 'name' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
                  </TableCell>
                  <TableCell>
                    Status
                  </TableCell>
                  <TableCell>
                    Enrollment Code
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </CustomTableHead>
              <TableBody>
                {sortedGroups.map((group, index) => (
                  <TableRow
                    key={index}
                  >
                    <TableCell>{group.name}</TableCell>
                    <TableCell>
                      {group.activeSessionId && group.Competitions?.startDate != null ? (
                        <Box>
                          Started on {formatDate(group.Competitions?.startDate)}
                          <br />
                          {calculateTimeAgo(group.Competitions?.startDate)}
                        </Box>
                      ) : (
                        <Box sx={{ minWidth: '185px' }}></Box>
                      )}
                    </TableCell>
                    <TableCell>{group.password ?? 'NO CODE'}</TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box sx={{ minWidth: '125px' }}>
                          {!group.activeSessionId ? (
                            <Button startIcon={<PlayArrowIcon />} onClick={() => handleToggleSessionClick(group)}>
                              Start term
                            </Button>
                          ) : (
                            <Button startIcon={<StopIcon />} onClick={() => handleToggleSessionClick(group)}>
                              End term
                            </Button>
                          )}
                        </Box>
                        <Button startIcon={<DeleteIcon />} onClick={() => handleDeleteClick(group)}>
                          Delete
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <ChangeConfirmationDialog
          title="Confirm Delete"
          message={`This action cannot be undone. Are you sure you want to delete "${groupToDelete?.name}"?`}
          open={openDialog}
          onClose={closeConfirmDeleteDialog}
          onCancel={closeConfirmDeleteDialog}
          onConfirm={handleConfirmDelete}
        />

        {endTermDialog}

      </div >
    );
  } else if (isLoaded) {
    return (
      <div>
        {createGroupComponent}
        <Typography align='center' variant='h2' sx={{ margin: 10 }}>No Courses Found</Typography>
      </div>
    );
  } else {
    return null;
  }
};

export default GroupsAdminPage;