import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Link as RouterLink } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  School,
  Group,
  CorporateFare,
  SatelliteAlt,
  Leaderboard,
  VerifiedUser,
  Dns,
} from '@mui/icons-material';

import { Roles } from 'src/BorgEnums';
import { LoggedInUserContext } from '../../Components/Admin/AdminPageContainer'; // Import context

const colorAuriaYellow = "#FFBC46" //TODO - replace with a global value from style sheet

const ADMIN_MENU_ITEMS = [
  { title: 'Users', icon: <Group />, path: 'users' },
  { title: 'Organizations', icon: <CorporateFare />, path: 'organizations' },
  { title: 'Courses', icon: <School />, path: 'courses' },
  { title: 'Sandboxes', icon: <SatelliteAlt />, path: 'sandboxes' },
  { title: 'Leaderboards', icon: <Leaderboard />, path: 'leaderboards' },
  { title: 'Permissions', icon: <VerifiedUser />, path: 'permissions' },
  { title: 'System', icon: <Dns />, path: 'system' }
];

const INSTRUCTOR_MENU_ITEMS: any[] = [];

type Props = {};

const AdminSidebar = (props: Props) => {
  const location = useLocation();
  const [basePath, setBasePath] = useState<string>(location.pathname.split('/')[1] || ""); // Initialize based on the current location
  const [selectedPath, setSelectedPath] = useState<string>(location.pathname.split('/')[2] || ""); // Initialize based on the current location

  const loggedInUser = useContext(LoggedInUserContext);   // Logged in User data (used for filtering pages)

  //Set selected path when menu item is chosen
  useEffect(() => {
    const pathParts = location.pathname.split('/');
    if (pathParts.length == 2) {
      setBasePath(pathParts[1].toLowerCase()); // Extract the path after '/'
      setSelectedPath('');
    } else if (pathParts.length == 3) {      
      setBasePath(pathParts[1].toLowerCase()); // Extract the path after '/'
      setSelectedPath(pathParts[2].toLowerCase()); // Extract the path after '/admin/'
    }
  }, [location]);

  // Conditionally filter menu items based on role and build type
  const isStandalone = process.env.REACT_APP_IS_STANDALONE === 'true';
  const isOrganizationAdmin = loggedInUser?.roles?.includes(Roles.OrganizationAdmin) ?? false;

  let filteredMenuItems: any[];
  if (basePath.includes("admin")) {
    filteredMenuItems = [...ADMIN_MENU_ITEMS];
  } else if (basePath.includes("instructor")) {
    filteredMenuItems = [...INSTRUCTOR_MENU_ITEMS];
  } else {
    filteredMenuItems = [];
  }

  if (isStandalone) {
    filteredMenuItems = filteredMenuItems.filter(item =>
      !['organizations', 'permissions'].includes(item.path)
    );
  }

  if (isOrganizationAdmin) {
    filteredMenuItems = filteredMenuItems.filter(item =>
      ['users', 'courses'].includes(item.path)
    );
  }

  let titleText;
  if (basePath == "admin") {
    titleText = "Admin";
  } else if (basePath == "instructor") {
    titleText = "Instructor";
  }

  return (
    <Box className='admin-sidebar'>
      <Box>

        {/* Title Section - TODO - Remove after app bar has tabs since that will tell the user what they are looking at*/}
        <Box sx={{ paddingLeft: '16px', paddingTop: '16px' }}>
          <Typography variant="h6">{titleText}</Typography>
        </Box>

        {/* Navigation Menu */}
        <List className="admin-sidebar-menu-items">
          {filteredMenuItems.map((item) => (
            <ListItem key={item.path} disablePadding>
              <ListItemButton
                component={RouterLink}
                to={`/admin/${item.path}`}
                sx={{
                  backgroundColor: selectedPath === item.path ? colorAuriaYellow : 'transparent', // Highlight on selection
                  borderRadius: '30px', // Capsule effect
                  padding: '8px 16px', // Add padding for the capsule
                  margin: '4px 4px', // Add spacing between items
                }}
              >
                <ListItemIcon
                  sx={{
                    color: selectedPath === item.path ? 'black' : '', // Highlight on selection
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.title}
                  primaryTypographyProps={{
                    sx: {
                      color: selectedPath === item.path ? 'black' : '', // Change color based on selection
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default AdminSidebar;