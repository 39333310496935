import React, { useCallback, useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, styled,
  Switch, Typography, Chip, TextField
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { getAllOrganizations, deleteOrganization, updateOrganizationPaidStatus, updateOrganizationUserLimit } from '../../Services/BorgAPIClient';
import moment from 'moment';
import ChangeConfirmationDialog from '../../Components/ChangeConfirmationDialog';

type Organization = {
  id: string;
  name: string;
  createdDate: Date;
  isPaid: boolean;
  userLimit: number;
};

const CustomTableHead = styled(TableHead)({
  backgroundColor: 'black',
  '& th': {
    color: 'white',
    cursor: 'pointer',
  },
});

const OrganizationsAdminPage: React.FC = () => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [organizationToDelete, setOrganizationToDelete] = useState<Organization | null>(null);
  const [sortBy, setSortBy] = useState<keyof Organization>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const fetchOrganizations = useCallback(async () => {
    try {
      const result = await getAllOrganizations();
      setOrganizations(result.data as Organization[]);
      setIsLoaded(true);
    } catch (error) {
      console.error('Failed to fetch organizations:', error);
    }
  }, []);

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations]);

  const handleSort = (column: keyof Organization) => {
    const isAsc = sortBy === column && sortDirection === 'asc';
    setSortBy(column);
    setSortDirection(isAsc ? 'desc' : 'asc');
  };

  const sortedOrganizations = [...organizations].sort((a, b) => {
    const compareResult = (aVal: any, bVal: any) => {
      if (typeof aVal === 'string' && typeof bVal === 'string') {
        return aVal.localeCompare(bVal);
      }
      if (aVal < bVal) return -1;
      if (aVal > bVal) return 1;
      return 0;
    };

    const result = compareResult(a[sortBy], b[sortBy]);
    return sortDirection === 'asc' ? result : -result;
  });

  const closeConfirmDeleteDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteClick = (organization: Organization) => {
    setOrganizationToDelete(organization);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (organizationToDelete) {
      await deleteOrganization(organizationToDelete.id);
      fetchOrganizations();
    }
    setOpenDialog(false);
    setOrganizationToDelete(null);
  };

  const handleTogglePaidStatus = async (organization: Organization) => {
    await updateOrganizationPaidStatus(organization.id, !organization.isPaid);
    fetchOrganizations();
  };

  const handleUserLimitChange = async (organization: Organization, newValue: number | null) => {
    //TODO: add auto save notification of change
    console.log(`Updating user limit for ${organization.name} to ${newValue}`);

    //Account for the situation when newValue is null
    if(newValue)
      updateOrganizationUserLimit(organization.id, newValue);
    else
      console.error("New User Limit value is null");
  };

  if (isLoaded && organizations.length > 0) {
    return (
      <Box sx={{
        overflow: 'auto',
        borderRadius: 1,
        marginY: 2,
        bgcolor: 'primary.dark'
      }}>
        <TableContainer component={Paper}>
          <Table>
            <CustomTableHead>
              <TableRow>
                <TableCell onClick={() => handleSort('name')}>
                  Organization Name {sortBy === 'name' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
                </TableCell>
                <TableCell onClick={() => handleSort('createdDate')}>
                  Created Date {sortBy === 'createdDate' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
                </TableCell>
                <TableCell onClick={() => handleSort('isPaid')}>
                  Payment Status {sortBy === 'isPaid' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
                </TableCell>
                <TableCell onClick={() => handleSort('userLimit')}>
                  User Limit {sortBy === 'userLimit' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </CustomTableHead>
            <TableBody>
              {sortedOrganizations.map((organization, index) => (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor: organization.isPaid ? '' : '#8e5d64',
                  }}
                >
                  <TableCell>{organization.name}</TableCell>
                  <TableCell>
                    <Chip
                      label={`Created ${moment(organization.createdDate).fromNow()}`}
                      color="primary"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Chip
                        label={organization.isPaid ? "Paid" : "Unpaid"}
                        color={organization.isPaid ? "success" : "error"}
                        size="small"
                      />
                      <Switch
                        checked={organization.isPaid}
                        onChange={() => handleTogglePaidStatus(organization)}
                        color="primary"
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      defaultValue={organization.userLimit}
                      onChange={(event) => handleUserLimitChange(organization, parseInt(event.target.value))}
                      inputProps={{ min: 5, max: 1000, step: 5 }} // Set min and max values for validation and step count
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Button
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDeleteClick(organization)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <ChangeConfirmationDialog
          title="Confirm Delete"
          message={`This action cannot be undone. Are you sure you want to delete "${organizationToDelete?.name}"?`}
          open={openDialog}
          onClose={closeConfirmDeleteDialog}
          onCancel={closeConfirmDeleteDialog}
          onConfirm={handleConfirmDelete}
        />
      </Box>
    );
  } else if (isLoaded) {
    return <Typography align='center' variant='h2' sx={{ margin: 10 }}>No Organizations Found</Typography>;
  } else {
    return null;
  }
};

export default OrganizationsAdminPage;