import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { getUserStats } from 'src/Services/BorgAPIClient';
import { LoggedInUserContext } from '../Admin/AdminPageContainer'; // Import context
import { Roles } from 'src/BorgEnums';

type UserStatType = {
  activeUsers: number;
  userLimit: number;
  numberOfAdmins: number;
};
type statChangeType = {
  statsChanged: boolean;
};

const colorAuriaYellow= "#FFBC46" //TODO - replace with a global value from style sheet

const AdminCountPanel = ({ statsChanged }: statChangeType) => {
  const [userStats, setUserStats] = useState<UserStatType | null>(null);
  const loggedInUser = useContext(LoggedInUserContext);

  useEffect(() => {
    init();
  }, [statsChanged, loggedInUser]);

  const init = async () => {
    if (loggedInUser)  //if logged in user has been obtained
    {
      try {
        let userStats: { data: UserStatType; }; //initialize as blank UserStatType

        if (loggedInUser?.roles[0] === Roles.OrganizationAdmin)
          userStats = await getUserStats(loggedInUser.organization);
        else
          userStats = await getUserStats();

        setUserStats(userStats.data as UserStatType);
      } catch (error) {
        console.error('Failed to fetch user stats:', error);
      }
    };
  };

  // Ensure adminCount is defined before using it
  const adminCount = userStats?.numberOfAdmins ?? 0;

  return (
    <Box className="admin-count-panel" sx={{
      borderRadius: 2,
      height: '50px',
      minWidth: '20px',
      maxWidth: 'auto',
      bgcolor: 'primary.light',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Typography variant="h1" sx={{ color: colorAuriaYellow, fontSize: '1.5rem', mr: 1, ml: 1 }}>
        {adminCount}
      </Typography>
      <Typography variant="body1" sx={{ fontSize: '.75rem', color: 'white', mr: .5, ml: .5 }}>
        {adminCount > 1 ? 'Admins' : 'Admin'}
      </Typography>
    </Box>
  );
};

export default AdminCountPanel;