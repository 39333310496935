import { Button } from "@mui/material";
import React, { useEffect } from "react";
import styles from "./ErrorPages.module.scss";
import { useNavigate, useLocation } from "react-router-dom";

interface IErrorLocation {
  errorCode?: number;
  errorText?: string;
  errorHelp?: string;
}

const defaultProps: IErrorLocation = {
  errorCode: undefined,
  errorText: "Looks like something broke!",
  errorHelp: "If problem persists contact support",
};

function Error(props: IErrorLocation) {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = React.useState<IErrorLocation>({} as IErrorLocation);
  
  props = { ...defaultProps, ...props };

    useEffect(() => {   
        let _error: IErrorLocation = {} as IErrorLocation;
        if (!location.state) {
            _error = props;
        }
        else {
            _error = location.state as IErrorLocation;
        }
        setError(_error);
    }, []);

  return (
    <div className={styles.unauthorized}>
      <h2 className={styles.errorMessage}>{error.errorText}</h2>
      {error.errorCode && 
        <h1 className={styles.errorCode}>{error.errorCode}</h1>
      }
      {error.errorHelp && (
        <p className={styles.errorHelp}>{error.errorHelp}</p>
      )}
      <Button className={styles.errorButton} onClick={() => navigate("/")}>
        Go to Login
      </Button>
    </div>
  );
}

export default Error;
