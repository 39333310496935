import { BorderBottom } from '@mui/icons-material';
import { PaletteMode } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const primaryMain = '#333';
const secondaryMain = '#FFBC46';
const transparent = '#00000000';

const palette = {
  primary: {
    main: primaryMain
  },
  secondary: {
    main: secondaryMain
  },
  text: {
    primary: '#ddd',
    secondary: '#999',
    disabled: '#888'
  },
  tonalOffset: 0.3,
  mode: 'dark' as PaletteMode
};

const theme = createTheme({
  palette: palette,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: transparent
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          "& .MuiAlert-icon": {
            fontSize: 32
          }
        },
        filledSuccess: {
          fontSize: 20
        },
        filledError: {
          fontSize: 20
        },
        filledWarning: {
          fontSize: 20
        },
        filledInfo: {
          fontSize: 20
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: primaryMain
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          // set default text color
          color: palette.text.primary
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: secondaryMain,
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: secondaryMain
          },
          "&.MuiInput-underline:after": {
            borderBottomColor: secondaryMain
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        textPrimary: ({ ownerState }) => ({
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
          // set default text color
          color: palette.text.primary
        }),
        textError: ({ ownerState }) => ({
          '&:hover': {
            backgroundColor: theme.palette.error.light,
          },
          // set error text color
          color: palette.text.primary
        })
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#888',
          '&.Mui-checked': {
            color: '#888',
          },
        },
      }
    }
  }
});

export default theme;