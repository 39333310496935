import { Box, Button, IconButton, Chip, Divider, List, ListItem, Typography, Tooltip, AlertColor } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { useCallback, useEffect, useState } from 'react';
import { getAllSandboxes, deleteSandbox, downloadSandboxToJson } from '../../Services/BorgAPIClient';
import moment from 'moment';
import ChangeConfirmationDialog from '../../Components/ChangeConfirmationDialog';

type Sandbox = {
  id: string;
  title: string;
  userName: string;
  description: string;
  timeCreated: Date;
  lastModified: Date;
  lastAccessed: Date;
  accessCount: number;
};

type Props = { onNotification: (message: string, notificationType?: AlertColor) => void};

const SandboxesAdminPage = (props: Props) => { 
  
  const [sandboxes, setSandboxes] = useState<Sandbox[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [sandboxToDelete, setSandboxToDelete] = useState<Sandbox | null>(null);

  const fetchSandboxes = useCallback(async () => {
    try {
      const result = await getAllSandboxes();
      setSandboxes(result.data as Sandbox[]);
      setIsLoaded(true);
    } catch (error) {
      console.error('Failed to fetch sandboxes:', error);
      props.onNotification("Failed to fetch sandboxes", "error");
    }
  }, []);

  // see https://dev.to/jasmin/how-to-use-async-function-in-useeffect-5efc
  useEffect(() => {
    fetchSandboxes();
  }, [fetchSandboxes]);

  const closeConfirmDeleteDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteClick = (sandbox: Sandbox) => {
    setSandboxToDelete(sandbox);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (sandboxToDelete) {
      try {
        await deleteSandbox(sandboxToDelete.id);
      }
      catch (error) {        
        props.onNotification("Failed to delete sandbox", "error");
      }
      fetchSandboxes();
    }
    setOpenDialog(false);
    setSandboxToDelete(null);
  };

  const handleDownloadClick = async (sandbox: Sandbox) => {
    try {
      await downloadSandboxToJson(sandbox.id, `${sandbox.title} ${sandbox.id}.json`);
    }
    catch (error) {
      console.error("Failed to download sandbox");
      props.onNotification("Failed to download sandbox", "error");
    }
  };

  const renderSandboxListItem = (sandbox: Sandbox, index: number) => {    

    // setup access chips

    let accessChips;
    let chipIndex = 0;

    const timeLastAccessed = moment(sandbox.lastAccessed);
    const timeNow = moment();
    const daysSinceLastAccessed = timeNow.diff(sandbox.lastAccessed, 'days')
    const accessCount = sandbox.accessCount ?? 0;
    const timeLastAccessedChip = (<Chip 
      key={chipIndex++}
      label={`Accessed ${timeLastAccessed.fromNow()}`} 
      color={daysSinceLastAccessed >= 30 ? "error" : "primary"} 
      size="small" 
    />);
    const accessCountChip = (<Chip key={chipIndex++} label={`Accessed ${accessCount} ${accessCount === 1 ? "time" : "times"}`} color="primary" size="small" />);

    if(sandbox.timeCreated === sandbox.lastModified && sandbox.accessCount === 1) {
      // brand new sandbox, hasn't been modified or accessed since creation (a 1-off)
      const timeCreated = moment(sandbox.timeCreated);
      const timeCreatedChip = (<Chip key={chipIndex++} label={`Created ${timeCreated.fromNow()}`} color="primary" size="small" />);
      const singleUseChip = (<Chip key={chipIndex++} label="Used Once" color="primary" size="small" />);
      accessChips = [singleUseChip, timeCreatedChip];
    } else if(sandbox.timeCreated === sandbox.lastModified) {
      // hasn't been modified
      const timeCreated = moment(sandbox.timeCreated);
      const timeCreatedChip = (<Chip key={chipIndex++} label={`Created ${timeCreated.fromNow()}`} color="primary" size="small" />);
      accessChips = [timeLastAccessedChip, accessCountChip, timeCreatedChip];
    } else {
      // has been previously modified
      const timeLastModified = moment(sandbox.lastModified);
      const timeLastModifiedChip = (<Chip key={chipIndex++} label={`Modified ${timeLastModified.fromNow()}`} color="primary" size="small" />);
      accessChips = [timeLastAccessedChip, accessCountChip, timeLastModifiedChip];
    }

    return (
      <div key={index}>
        <ListItem sx={{ display: "flex", justifyContent: "space-between"}}>
          <Box>
            <Typography variant="h6">{sandbox.title}</Typography>
            {sandbox.userName && <Typography variant="subtitle1">{`Owner: ${sandbox.userName}`}</Typography>}
          </Box>
          <Box sx={{ display: "flex", flexGrow: 0.1, justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", gap: 1, marginY: 'auto', }}>
              {!sandbox.userName && <Chip label="No Owner" color="error" size="small" />}
              {accessChips}
            </Box>
            <Box sx={{ display: "flex", gap: 1, justifyContent: "space-between"}}>
              <Button
                startIcon={<DeleteIcon />}
                onClick={() => handleDeleteClick(sandbox)}
              >
                Delete
              </Button>
              <Tooltip title="Download to a format compatible with the Desktop version.">
                <IconButton onClick={() => handleDownloadClick(sandbox)}>
                  <DownloadIcon />                
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </ListItem>
        { index < sandboxes.length-1 && <Divider/> /* don't draw Divider if it's the last item in the list */ }
      </div>
    );
  }

  if(isLoaded && sandboxes.length > 0) {

    const sortedSandboxes = sandboxes.sort((a, b) => {
      if (a.lastAccessed > b.lastAccessed) {
        return -1;
      }
      if (a.lastAccessed < b.lastAccessed) {
        return 1;
      }
      return 0;
    });

    return (
      <div>
        <Box sx={{
          overflow: 'auto',
          marginY: 2,
          borderRadius: 1,
          bgcolor: 'primary.dark'
        }}>
        <List>
          {/* Mapping sandboxes to list items */}
          {sortedSandboxes.map((sandbox, index) => renderSandboxListItem(sandbox, index))}
        </List>
      </Box>

      {/* Deletion Confirmation Dialog */}
      <ChangeConfirmationDialog 
        title="Confirm Delete" 
        message={
          `This action cannot be undone.  Are you sure 
          you want to delete "${sandboxToDelete?.title}" for all users?`}
        open={openDialog} 
        onClose={closeConfirmDeleteDialog} 
        onCancel={closeConfirmDeleteDialog} 
        onConfirm={handleConfirmDelete}
      />

    </div> ); 
  } else if(isLoaded) {
    return <div><Typography align='center' variant='h2' sx={{ margin: 10 }} >No Sandboxes Found</Typography></div>
  } else {
    return null;
  }
}

export default SandboxesAdminPage;