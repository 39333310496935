import React, { useContext } from 'react';
import { useNavigate, Link as RouterLink } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { Roles } from 'src/BorgEnums';
import { handleLogout } from 'src/Components/LogoutUtils';
import { LoggedInUserContext } from '../../Components/Admin/AdminPageContainer'; // Import context

import borgLogo from "../../assets/img/Borg-logo.png";
import kepleraLogo from "../../assets/img/Keplera-logo.png";

const isKepleraVersion = process.env.REACT_APP_PRODUCT_NAME === "keplera";
const productLogo = isKepleraVersion ? kepleraLogo : borgLogo;

export const APP_BAR_HEIGHT_PX = 140; // rough estimate
const BUTTON_PADDING = 2;
const colorBorgBlue = "#5BF8FF";

type Props = {};

const AdminAppBar = (props: Props) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const loggedInUser = useContext(LoggedInUserContext);

  const navigate = useNavigate();

  const handleOpenNavMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(e.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClickNavButton = (e: React.MouseEvent<HTMLElement>, url: string = "") => {
    setAnchorElNav(null);
    if (url) navigate(url);
  };

  const handlePlayClick = () => {
    window.open("/play", "_blank", "noreferrer");
  }

  //Conditionally render the Admin page based on role and build type 
  const isStandalone = process.env.REACT_APP_IS_STANDALONE === 'true';
  const isOrganizationAdmin = loggedInUser?.roles?.includes(Roles.OrganizationAdmin) ?? false;

  const pages = [
    { key: "admin", title: 'Admin', url: "/admin/users" }, 
    { key: "instructor", title: 'Instructor', url: "/instructor" }, 
  ];
  let filteredPages = pages;

  // TODO remove if not used to change what the top bar shows
  // //Page filtering
  // if (isStandalone) { //Remove all pages not included in standalone build
  //   filteredPages = filteredPages.filter(page => page !== 'Organizations' && page !== 'Permissions');
  // }

  // if (isOrganizationAdmin) {  //Organization Admin role can only see the Users page
  //   filteredPages = ['Users', 'Courses'];
  // }

  return (
    <AppBar position="sticky">
      <Container maxWidth={false}>
        <Toolbar disableGutters>

          <Box sx={{ flexGrow: 0.5, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {filteredPages.map((page) => (
                <MenuItem key={page.key} onClick={(e) => handleClickNavButton(e, page.url)}>
                  <Typography textAlign="center">{page.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <div className="borg-logo-appbar">
            <img alt="logo" src={productLogo} />
          </div>

          <Box sx={{ flexGrow: 1, gap: 1, display: { xs: 'none', md: 'flex' }, paddingLeft: BUTTON_PADDING }}>
            { /* TODO - Use Tabs */}
            {filteredPages.map((page) => (
              <Link key={page.key} onClick={(e) => handleClickNavButton(e, page.url)} sx={{ textDecoration: 'none' }}>
                <Button
                  key={page.key}
                  sx={{ my: 2, display: 'block' }}
                >
                  {page.title}
                </Button>
              </Link>
            ))}

              <Button
                sx={{ my: 2, display: 'block',
                  backgroundColor: colorBorgBlue,
                  borderRadius: '30px',
                  color: 'black',
                  paddingTop: '8px', // Add padding to help with the text offset
                }}
                onClick={ handlePlayClick}
              >
                Play
              </Button>
          </Box>

          <Tooltip title="Logout">
            <IconButton
              sx={{ marginLeft: 'auto' }}
              size="large"
              aria-label="=logout"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => handleLogout(navigate)}
              color="inherit"
            >
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default AdminAppBar;