import { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { getWebApiUrl } from "../Services/AxiosService";
import { getUser } from "../Services/BorgAPIClient";
import { Roles } from "src/BorgEnums";

import borgLogo from "../assets/img/Borg-logo.png";
import kepleraLogo from "../assets/img/Keplera-logo.png";

const isKepleraVersion = process.env.REACT_APP_PRODUCT_NAME === "keplera";
const productLogo = isKepleraVersion ? kepleraLogo : borgLogo;

// Prioritize process.env for local development and use window._env_ for production
const sysHighClassification = process.env.REACT_APP_SYS_HIGH || window._env_?.REACT_APP_SYS_HIGH;
const udlApiURL = process.env.REACT_APP_UDL_API_URL || window._env_?.REACT_APP_UDL_API_URL;
const udlUsername = process.env.REACT_APP_UDL_USERNAME || window._env_?.REACT_APP_UDL_USERNAME;
const udlPassword = process.env.REACT_APP_UDL_PASSWORD || window._env_?.REACT_APP_UDL_PASSWORD;
// Version number for cache invalidation
const unityFilesVersion = process.env.REACT_APP_UNITY_FILES_VERSION || window._env_?.REACT_APP_UNITY_FILES_VERSION;

const UnityViewer = forwardRef((props, ref) => {

  const ASPECT_RATIO = 16 / 9;

  const navigate = useNavigate();
  const cookies = new Cookies();

  const canvasRef = useRef(null);

  const [canvasHeight, setCanvasHeight] = useState(null);
  const [canvasWidth, setCanvasWidth] = useState(null);

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    handleResize
  }));

  const { unityProvider, loadingProgression, isLoaded, unload, sendMessage, addEventListener, removeEventListener } = useUnityContext({
    loaderUrl: `unitybuild/Build.loader.js?v=${unityFilesVersion}`,
    dataUrl: `unitybuild/Build.data?v=${unityFilesVersion}`,
    frameworkUrl: `unitybuild/Build.framework.js?v=${unityFilesVersion}`,
    codeUrl: `unitybuild/Build.wasm?v=${unityFilesVersion}`,
    streamingAssetsUrl: `StreamingAssets_v${unityFilesVersion}`,
  });

  useEffect(() => {
    // HACK - Prevent non-logged in or disabled users from getting to dashboard
    const getLoggedInUser = async () => {
      const userId = JSON.parse(localStorage.getItem('userId'));
      const canPlayStatus = JSON.parse(localStorage.getItem('canPlay'));
      if (userId === null) {
        console.log("No logged in user.  Returning to login screen.");
        navigate("/");
      } else if (!canPlayStatus) {
        console.log("User disabled.  Returning to login screen.");
        navigate("/");
      }
    };

    getLoggedInUser();
  }, []);

  async function handleLogOut(userId) {
    console.log(`Logging out User ID ${userId}`);
    localStorage.removeItem('refresh-token');
    localStorage.removeItem('x-auth-token');
    localStorage.removeItem('userId');
    cookies.remove('username');
    cookies.remove('userId');
    await unload();
    console.log("UnityViewer unloaded")
    navigate("/");
  };

  async function handleQuit() {
    console.log("Exiting to Out of Borg Game");
    await unload();
    console.log("UnityViewer unloaded")

    //Obtain logged in user profile information inorder to identify role
    const userId = JSON.parse(localStorage.getItem('userId'));

    let loggedInUser = await getUser(userId.replace(/"|'/g, ''));
    loggedInUser = loggedInUser.data;

    //If logged in user is of an admin distinction close play tab to return to admin panel. Otherwise route to home
    if (loggedInUser.roles.length > 0 &&
      (loggedInUser.roles[0] == Roles.SystemAdmin || loggedInUser.roles[0] == Roles.OrganizationAdmin)) {
      window.close();
    }
    else {
      navigate("/");
    }

  };

  async function handleGetTextFromClipboard() {
    let textOnClipboard;
    try {
      textOnClipboard = await navigator.clipboard.readText();
    } catch (err) {
      // permission denied by browser
      textOnClipboard = "BORGERR - Clipboard Read Permission Denied";
    }
    sendMessage("StartupManager", "SetTextFromClipboard", textOnClipboard);
  }

  function handleResize() {
    if (isLoaded) {

      // calculate container aspect ratio
      const containerWidth = window.innerWidth;
      let containerHeight = window.innerHeight;
      const showBanner = sessionStorage.getItem("showBanner");
      if (showBanner != "false")  //If the banner is not disabled then shorten the recorded height of the parent container
      {
        containerHeight = containerHeight - 35;
      }
      const containerAspectRatio = containerWidth / containerHeight;

      //console.log(`BEFORE - Canvas ${canvasAspectRatio} (${canvasWidth} x ${canvasHeight}) Container: ${containerAspectRatio} (${containerWidth} x ${containerHeight})`);
      // resize canvas to fit container
      if (containerAspectRatio >= ASPECT_RATIO) {
        // Fit vertically
        setCanvasHeight(containerHeight);
        setCanvasWidth(containerHeight * ASPECT_RATIO);
        //console.log(`AFTER V - Canvas: ${(containerHeight * ASPECT_RATIO) / containerHeight} (${containerHeight * ASPECT_RATIO} x ${containerHeight}) Container: ${containerAspectRatio} (${containerWidth} x ${containerHeight})`);
      } else {
        // Fit horizontally
        setCanvasHeight(containerWidth / ASPECT_RATIO);
        setCanvasWidth(containerWidth);
        //console.log(`AFTER H - Canvas: ${containerWidth / (containerWidth / ASPECT_RATIO)} (${containerWidth} x ${containerWidth / ASPECT_RATIO}) Container: ${containerAspectRatio} (${containerWidth} x ${containerHeight})`);
      }
    }
  };

  useEffect(() => {
    if (isLoaded) {
      console.log("UnityViewer loaded");
      const username = cookies.get("username");
      const userId = cookies.get("userId");
      //console.log(`Username=${username} UserID=${userId}`);
      // TODO - Send as one JSON string
      sendMessage("StartupManager", "SetLoggedInUserName", username ? username : "");
      sendMessage("StartupManager", "SetLoggedInUserID", userId ? userId : "");
      sendMessage("StartupManager", "SetWebApiUrl", getWebApiUrl());
      sendMessage("StartupManager", "SetSystemHighClassification", sysHighClassification);
      sendMessage("StartupManager", "SetUDLApiURL", udlApiURL);
      sendMessage("StartupManager", "SetUDLUsername", udlUsername);
      sendMessage("StartupManager", "SetUDLPassword", udlPassword);
      sendMessage("StartupManager", "Initialize");
      handleResize();
    }
    addEventListener("LogOut", handleLogOut);
    addEventListener("Quit", handleQuit);
    addEventListener("GetTextFromClipboard", handleGetTextFromClipboard);
    window.addEventListener("resize", handleResize);
    return () => {
      removeEventListener("LogOut", handleLogOut);
      removeEventListener("Quit", handleQuit);
      removeEventListener("GetTextFromClipboard", handleGetTextFromClipboard);
      window.removeEventListener("resize", handleResize);
    };
  }, [isLoaded]);

  // while loading, don't use the unityviewer class, since that causes the logo and text 
  // to be shifted left by the hidden but still space-occupying Unity canvas
  let unityViewerClassName = isLoaded ? "unityviewer" : "";

  return (
    <div id="unity-viewer" className={unityViewerClassName}>
      {!isLoaded && (
        <div className="main">
          <div className="borg-logo">
            <img alt="logo" src={productLogo} />
          </div>
          <p className="loading">Loading... {Math.round(loadingProgression * 100)}%</p>
        </div>
      )}
      <Unity
        ref={canvasRef}
        unityProvider={unityProvider}
        style={{
          height: canvasHeight,
          width: canvasWidth,
          visibility: isLoaded ? "visible" : "hidden",
          margin: 'auto'  // Center the Unity component
        }}
      />
    </div>
  );
});

export default UnityViewer;