import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, styled, DialogContent, Dialog, TextField, DialogActions, DialogTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { getAccessCodes, generateAccessCode, createOrganization, assignOrganizationToAccessCode, deleteAccessCode, getOrganization } from 'src/Services/BorgAPIClient';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import ChangeConfirmationDialog from 'src/Components/ChangeConfirmationDialog';

type accessCodeType = {
  id: string;
  code: string;
  assignedOrganization: string;
  assignedOrganizationId: string;
  numberOfUsersAssigned: string;
  dateGenerated: Date;
  dateAssigned: Date;
};

const CustomTableHead = styled(TableHead)({
  backgroundColor: 'black',
});

const PermissionsAdminPage = () => {
  const [allAccessCodes, setAccessCodes] = useState<accessCodeType[]>([]);
  const [isOpen, setOpen] = useState(false);
  const [organizationInput, setOrganizationInput] = useState("");
  const [accessCodeSelected, setSelectedAccessCode] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [accessCodeToDelete, setAccessCodeToDelete] = useState<accessCodeType | null>(null);

  //Obtain all Access Codes
  const fetchAccessCodes = useCallback(async () => {
    try {
      let accessCodeData = await getAccessCodes();
      setAccessCodes(accessCodeData.data as accessCodeType[]);
    } catch (error) {
      console.error('Failed to fetch access codes:', error);
    }
  }, []);

  useEffect(() => {
    fetchAccessCodes();
  }, [fetchAccessCodes]);

  //Event Functions
  const handleAddAccessCodeClick = async () => {
    try {
      await generateAccessCode();   //Create access code 
      fetchAccessCodes();     //refresh screen to view newly created access code
    } catch (error) {
      console.error('Failed to generate access code:', error);
    }
  }

  const handleEditClick = (accessCode: string) => {
    setOpen(true);
    setSelectedAccessCode(accessCode);
  }

  const handleConfirmAssignAndCreate = async () => {
    //If Organization doesn't exist Create Organization
    var orgResult = await getOrganization(organizationInput);
    orgResult = orgResult.data; //Return back just the data object of the organization returned
    if(orgResult=="") //If no data returned create org
        orgResult = await createOrganization(organizationInput);

    //Assign Organization to Access Code
    try {
      await assignOrganizationToAccessCode(accessCodeSelected, orgResult.id);
    } catch (error: any) {
      console.error('Error assigning organization to access code:', error);
      setOpen(false);
    }
    
    setOpen(false);
    fetchAccessCodes();     //refresh screen to view newly created access code
  }

  function handleCloseAssign(): void {
    setOpen(false);
  }

  const closeConfirmDeleteDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteClick = (accessCode: accessCodeType) => {
    setAccessCodeToDelete(accessCode);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (accessCodeToDelete) {
      await deleteAccessCode(accessCodeToDelete.id);
      fetchAccessCodes();
    }
    setOpenDialog(false);
    setAccessCodeToDelete(null);
  };

  const calculateTimeAgo = (time: Date) => {
    return moment(time).fromNow();
  }

  return (
    <div>
      <Box sx={{
          marginY: 2,
          borderRadius: 1,
        }}
      >
        <Button variant="contained" color="primary"
          onClick={() => handleAddAccessCodeClick()}
          startIcon={<AddIcon />} >
          Add Access Code
        </Button>
      </Box>
      <Box sx={{
        overflow: 'auto',
        marginY: 2,
        borderRadius: 1,
        bgcolor: 'primary.dark'
      }}>
        <Dialog open={isOpen} onClose={handleCloseAssign}>
          <DialogTitle>Assign Organization to Access Code</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="organization"
              label="Organization Name"
              type="text"
              fullWidth
              variant="standard"
              onChange={(org) => setOrganizationInput(org.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAssign}>Cancel</Button>
            <Button onClick={handleConfirmAssignAndCreate}>Confirm</Button>
          </DialogActions>
        </Dialog>
        <TableContainer>
          <Table>
            <CustomTableHead>
              <TableRow >
                <TableCell>Access Code</TableCell>
                <TableCell>Assigned Organization</TableCell>
                <TableCell>Created</TableCell>
                <TableCell># Accounts Created</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </CustomTableHead>
            <TableBody>
              {allAccessCodes.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.code}</TableCell>
                  <TableCell>{row.assignedOrganization}</TableCell>
                  <TableCell>{calculateTimeAgo(row.dateGenerated)}</TableCell>
                  <TableCell>{row.numberOfUsersAssigned || 0}</TableCell>
                  <TableCell sx={{ display: "flex", justifyContent: "right" }}>
                    <Button
                      startIcon={<EditIcon />}
                      onClick={() => handleEditClick(row.code)}
                      disabled={!!row.assignedOrganization} // Disable if assignedOrganization has a value
                    >
                      EDIT ORG
                    </Button>
                    <Button
                      startIcon={<DeleteIcon />}
                      onClick={() => handleDeleteClick(row)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box >
      
      {/* Deletion Confirmation Dialog */}
      <ChangeConfirmationDialog 
        title="Confirm Delete" 
        message={
          `This action cannot be undone.  Are you sure 
          you want to delete "${accessCodeToDelete?.code}"?`}
        open={openDialog} 
        onClose={closeConfirmDeleteDialog} 
        onCancel={closeConfirmDeleteDialog} 
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default PermissionsAdminPage;