import React, { createContext, useEffect, useState } from 'react';
import AdminAppBar from "../../Components/Admin/AdminAppBar";
import AdminAppSidebar from "./AdminAppSideBar";
import auriaLogo from "../../assets/img/AURIA_LOGO_WHITE+COLOR_64.png";
import Box from '@mui/material/Box';
import { getUser } from 'src/Services/BorgAPIClient';

type User = {
  id: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  rankTitle: string;
  roles: number[];
  enabled: boolean;
  resetPasswordPending: boolean;
  lastActive: Date;
  lastJoinedSessionID: string;
  organization: string;
};

// Create context to share current logged in user data to all components within the admin container
const LoggedInUserContext = createContext<User | null>(null);

type Props = { children: React.ReactNode };

const AdminPageContainer = (props: Props) => {
  const [loggedInUser, setLoggedInUser] = useState<User | null>(null);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const userId = JSON.parse(localStorage.getItem('userId') as string);
      if (userId) {
        const user = await getUser(userId.replace(/"|'/g, ''));
        if (user) {
          setLoggedInUser(user.data);
        }
      }
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  };

  return (
    <LoggedInUserContext.Provider value={loggedInUser}>
      <Box className="admin-page-container">
        <AdminAppBar /> {/* Display AppBar above the content */}
        <Box className='admin-page-container-content'>
          {/* Sidebar */}
          <Box sx={{ flex: 1, bgcolor: 'background.paper', maxWidth: 'fit-content' }}>
            <AdminAppSidebar />
          </Box>

          {/* Main Content Area */}
          <Box className='admin-main-content'>

            {/* Page Content */}
            {props.children}

            {/* Footer */}
            <Box className="login-footer">
              <a href="https://www.auria.space/" target="_blank">
                <img alt="Auria, LLC Logo" src={auriaLogo} />
              </a>
              <div className="copyright">
                © 2022 Boecore, LLC, a wholly owned subsidiary of Auria Space, LLC
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </LoggedInUserContext.Provider>
  );
};

export default AdminPageContainer;
export { LoggedInUserContext };