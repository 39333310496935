import React, { FormEvent, useState } from 'react';
import BorgStyleTextField from "./BorgStyleTextField";
import { useStripe, useElements, Elements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Link, useNavigate } from "react-router-dom";
import { registerPaymentFreeUser, registerUserWithOrganization, stripePayandRegisterUser, validateUserRegistration } from "../Services/BorgAPIClient";
import BorgStyleButton from './BorgStyleButton';


// This tells Typescript that window._env_ might exist and what properties it might have
declare global {
  interface Window {
    _env_?: {
      REACT_APP_STRIPE_PUBLIC_KEY?: string;
    };
  }
}

 // Prioritize process.env for local development and use window._env_ for production
 // We may want to leave the test key on the process.env and update the window._env_ to Stripes live account key when available
const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY || window._env_?.REACT_APP_STRIPE_PUBLIC_KEY || '';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

// https://docs.stripe.com/js/appendix/style
//TODO - replace const with a global value from style sheet
const colorBorgOrange = "rgb(255, 182, 61)";
const colorBorgBlue = "rgb(87,236,243)";
const colorAuriaYellow= "#FFBC46" 
const colorError = "red";
const CARD_NUMBER_OPTIONS = {
  placeholder: 'Card Number',
  style: {
    base: {
      iconColor: colorBorgBlue,
      color: colorBorgOrange,
      fontFamily: 'sans-serif',
      fontSize: '16px',
      '::placeholder': {
        color: 'rgb(118, 69, 26)',
      },
      '::selection': {
        backgroundColor: colorAuriaYellow,
      },
      ':-webkit-autofill': {
        color: colorBorgOrange,
      },
    },
    invalid: {
      iconColor: colorError,
      color: colorError,
    },
  },
};

const CARD_DATE_OPTIONS = {
  placeholder: 'Expiration Date',
  style: {
    base: {
      iconColor: colorBorgBlue,
      color: colorBorgOrange,
      fontFamily: 'sans-serif',
      fontSize: '16px',
      '::placeholder': {

        color: 'rgb(118, 69, 26)',
      },
      '::selection': {
        backgroundColor: colorAuriaYellow,
      },
      ':-webkit-autofill': {
        color: colorBorgOrange,
      },
    },
    invalid: {
      iconColor: colorError,
      color: colorError,
    },
  },
};

const CARD_CVC_OPTIONS = {
  placeholder: 'CVC',
  style: {
    base: {
      iconColor: colorBorgBlue,
      color: colorBorgOrange,
      fontFamily: 'sans-serif',
      fontSize: '16px',
      '::placeholder': {

        color: 'rgb(118, 69, 26)',
      },
      '::selection': {
        backgroundColor: colorAuriaYellow,
      },
      ':-webkit-autofill': {
        color: colorBorgOrange,
      },
    },
    invalid: {
      iconColor: colorError,
      color: colorError,
    },
  },
};

interface FieldErrors {
  [key: string]: string | undefined;
}
interface PurchaseRegistrationProps {
  pageType: string;
}

const PurchaseRegistration: React.FC<PurchaseRegistrationProps> = ({ pageType }) => (
  <Elements stripe={stripePromise}>
    <PurchaseRegistrationContent pageType={pageType} />
  </Elements>
);

const PurchaseRegistrationContent: React.FC<PurchaseRegistrationProps> = ({ pageType }) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [rankTitle, setRankTitle] = useState("");
  const [organization, setOrganization] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [nationality, setNationality] = useState("");
  const [isFreeUser, setIsFreeUser] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  
  const rankTitleField = (
    <BorgStyleTextField
      id="textbox-ranktitle"
      label="Rank/Title"
      value={rankTitle}
      errorText={fieldErrors?.rankTitle}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRankTitle(e.target.value)}
    />
  );
  
  const organizationField = (pageType: string) => ( 
    <BorgStyleTextField
      id="textbox-organization"
      label="Organization"
      autoFocus = {pageType !== "Standalone" ?? true}
      required
      value={organization}
      errorText={fieldErrors?.organization}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setOrganization(e.target.value);
        resetErrors();
        resetFieldErrors('organization');
      }}
    />
  );
  
  const usernameField = (pageType: string) => ( 
    <BorgStyleTextField
      id="textbox-username"
      label="Username"
      required
      autoFocus={pageType === "Standalone" ?? true}
      value={userName}
      errorText={fieldErrors?.username}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setUserName(e.target.value);
        resetErrors();
        resetFieldErrors('username');
      }}
    />
  );
  
  const passwordField = ( 
    <BorgStyleTextField
      id="textbox-password"
      label="Password"
      required
      type="password"
      value={password}
      errorText={fieldErrors?.password}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        resetErrors();
        resetFieldErrors('password');
      }}
    />
  );
  
  const emailField = ( 
    <BorgStyleTextField
      id="textbox-email"
      label="Email"
      required
      type="email"
      value={email}
      errorText={fieldErrors?.email}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        resetErrors();
        resetFieldErrors('email');
      }}
    />
  );

  const accessCodeField = ( 
    <BorgStyleTextField
      id="textbox-accesscode"
      label="Access Code"
      required
      value={accessCode}
      errorText={fieldErrors?.accessCode}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setAccessCode(e.target.value);
        resetErrors();
        resetFieldErrors('accessCode');
      }}
    />
  );

  const firstNameField = ( 
    <BorgStyleTextField
      id="textbox-firstname"
      label="First Name"
      value={firstName}
      errorText={fieldErrors?.firstName}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
    />
  );

  const lastNameField = ( 
    <BorgStyleTextField
      id="textbox-lastname"
      label="Last Name"
      value={lastName}
      errorText={fieldErrors?.lastName}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
    />
  );

  const nationalityField = (
    <BorgStyleTextField
      id="textbox-nationality"
      label="Nationality"
      required
      value={nationality}
      errorText={fieldErrors?.nationality}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setNationality(e.target.value);
        resetErrors();
        resetFieldErrors('nationality');
      }}
    />
  );

  const resetErrors = () => {
    setError("");
  };

  const resetFieldErrors = (field: keyof FieldErrors) => {
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [field]: undefined,
    }));
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const userData = {
      userName,
      password,
      email,
      firstName,
      lastName,
      rankTitle,
      organization,
      accessCode,
      isFreeUser,
      nationality,
    };

    setSubmitting(true);

    try {
      // First validate user registration
      await validateUserRegistration(userData);

    } catch (error: any) {
      setSubmitting(false);
      if (error.errors) {
        const serverErrors = error.errors;
        const newFieldErrors: FieldErrors = {};

        Object.keys(serverErrors).forEach((field: string) => {
          newFieldErrors[field.charAt(0).toLowerCase() + field.slice(1)] = serverErrors[field][0];
        });

        setFieldErrors(newFieldErrors);
        return;
      } else if (error.title) {
        // Handle the specific error for the username
        console.error("error.title: ", error.title)
        setError(error.title)
        return;
      } else {
        setError("Registration failed. Please try again.");
        return;
      }
    }

    if (pageType === "JoinOrganization")//If user joining organization to register execute proper API pathway
    {
      try {
        // Call to backend to register user
        const response = await registerUserWithOrganization(userData);        
        if (response.userId) {
          let message = `New user \"${userData.userName}\" registered with organization \"${userData.organization}\"`;
          let warning
          if(response.message) warning = `NOTE: ${response.message}`;
          navigate('/success', { state: { message: message, warning: warning, pageType: pageType } });
        } else {
          if (response.isFieldError) {
            setError(response.errors.join(" "));
            setSubmitting(false);
          } else {
            navigate('/failed', { state: { message: response.message || "Unknown error occurred." } });
          }
        }
      } catch (err: any) {
        console.error("An error occurred during registration processing.", err);
        navigate('/failed', { state: { title: err.title || "An internal error occurred.", errors: err.errors, pageType: pageType } });
      }
    }
    else if (pageType === "Standalone") {
      try {
        userData.isFreeUser = true;

        // Call to backend to register user
        const userId = await registerPaymentFreeUser(userData);

        if (userId) {
          navigate('/success', { state: { message: userData.userName + " registered", pageType: pageType } });
        } else {
          navigate('/failed', { state: { message: "Unknown error occurred." } });
        }
      } catch (err: any) {
        console.error("An error occurred during registration processing.", err);
        navigate('/failed', { state: { title: err.title || "An internal error occurred.", errors: err.errors, pageType: pageType } });
      }

    }
    else if (pageType === "PurchaseSubscription")//If purchasing subscription execute proper API pathway
    {
      try {
        //Check if stripe is loaded and pull the card number element 
        if (!stripe || !elements) {
          setError("Stripe has not loaded yet.");
          return;
        }
        const cardNumberElement = elements.getElement(CardNumberElement);

        if (!cardNumberElement) {
          setError("Card details are not entered.");
          return;
        }

        // Create payment method
        // createPaymentMethod returns the paymentMethod that can be used to charge
        // or attach to a customer for future payments
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardNumberElement,
          billing_details: {
            name: `${userData.firstName} ${userData.lastName}`,
            email: userData.email,
          },
        });

        if (error) {
          setSubmitting(false);
          setError(error.message || "An unknown error occurred");
          return;
        }

        // Call to backend to register user and handle payment
        const response = await stripePayandRegisterUser(userData, paymentMethod.id);
        if (response.userId) {
          navigate('/success', { state: { message: response.message } });
        } else {
          if (response.isFieldError) {
            setError(response.errors.join(" "));
            setSubmitting(false); 
          } else {
              navigate('/failed', { state: { message: response.message || "Unknown error occurred." } });
          }
        }
      } catch (err: any) {
        console.error("An error occurred during payment processing.", err);
        navigate('/failed', {
          state: {
            title: err.title || "An internal error occurred.",
            errors: err.errors, pageType: pageType
          }
        });
      }
    }
  };

  let buttonTitle = submitting ? "Submitting... " : "Submit";
  
  let firstColumn;
  if(pageType === "Standalone") {
    firstColumn = (
      <div className="box-column">
        {usernameField(pageType)}
        {passwordField}
      </div>
    );
  } else {
    firstColumn = (
      <div className="box-column">
        {organizationField(pageType)}
        {accessCodeField}
        {nationalityField}
      </div>
    );
  }

  let secondColumn;
  if(pageType === "Standalone") {
    secondColumn = (
      <div className="box-column">
        {emailField}
        {organizationField(pageType)}
        {nationalityField}
      </div>
    );
  } else {
    secondColumn = (
      <div className="box-column">
        {usernameField(pageType)}
        {passwordField}
        {emailField}
      </div>
    );
  }

  let thirdColumn;
  if(pageType === "Standalone") {
    thirdColumn = (
      <div className="box-column">
        {firstNameField}
        {lastNameField}
        {rankTitleField}
      </div>
    );
  } else {
    thirdColumn = (
      <div className="box-column">
        {firstNameField}
        {lastNameField}
        {rankTitleField}
      </div>
    );
  }

  const accountInformationForm =
    (
      <div className="purchase-registration-container">
        <div className="header">Account Info</div>
        <div className="box">
          {firstColumn}
          {secondColumn}
          {thirdColumn}
        </div>
        {pageType !== "Standalone" && (<div className="copyright">Be sure to enter "Organization" and "Access Code" exactly as they were provided to you.</div>)}
        {pageType !== 'PurchaseSubscription' && (<BorgStyleButton id="button-submit" disabled={submitting} onClick={handleSubmit}>{buttonTitle}</BorgStyleButton>)}
        {pageType !== 'PurchaseSubscription' && error && (<div className="error">{error}</div>)}
      </div>
    )

  let purchaseSubscriptionForm;
  if (pageType === 'PurchaseSubscription') {
    purchaseSubscriptionForm =
      (
        <div className="purchase-registration-container">
          <div className="header">Payment Method</div>
          <div className="box">
            <div className="box-column">
              <CardNumberElement className="stripe-textfield" options={CARD_NUMBER_OPTIONS} />
            </div>
            <div className="box-column">
              <CardExpiryElement className="stripe-textfield" options={CARD_DATE_OPTIONS} />
            </div>
            <div className="box-column">
              <CardCvcElement className="stripe-textfield" options={CARD_CVC_OPTIONS} />
            </div>
          </div>
          <BorgStyleButton id="button-submit" disabled={submitting} onClick={handleSubmit}>{buttonTitle}</BorgStyleButton>
          {error && (<div className="error">{error}</div>)}
        </div>
      )
  }
  else {
    purchaseSubscriptionForm = (<div></div>)
}

  return (
    <div>
      {accountInformationForm}
      {purchaseSubscriptionForm}
      <div className="footer">
        <Link id="link-button-backtologin" className="link-button" to="/">Back to Login</Link>
      </div>
    </div>
  );
};

export default PurchaseRegistration;