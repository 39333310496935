import React from "react";
import { Box, Typography } from '@mui/material';

type Props = {};

const InstructorsDashboardPage = (props: Props) => { 

  return (
    <Box textAlign='center' sx={{ margin: 10 }}>
      <Typography variant='h1' align='center'>
        COMING SOON!
      </Typography>
    </Box>
  );
}

export {};
export default InstructorsDashboardPage;