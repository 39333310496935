import { useState } from 'react';
import { Button } from '@mui/material';
import AboutModal from 'src/Components/AboutModal';

import auriaLogo from "../../assets/img/AURIA_LOGO_WHITE+COLOR_64.png";

type Props = { 
  error?: string,
  message?: string,
  fixedFooter?: boolean,
  children: React.ReactNode, 
};

function MainPageTemplateCustomLayout(props: Props) {

  const [isAboutOpen, setIsAboutOpen] = useState(false);
  
  const toggleAbout = () => {
    setIsAboutOpen(!isAboutOpen);
  }

  return (
    <div>
      {props.children}
      <div className={props.fixedFooter ? "login-footer-fixed" : "login-footer"}>
        <a href="https://www.auria.space/" target="_blank" ><img alt="Auria, LLC Logo" src={auriaLogo} /></a>
        <div className="copyright">© 2022 Boecore, LLC, a wholly owned subsidiary of Auria Space, LLC</div>
        <Button
          id="button-about"
          sx={{
            border: '2px solid',
            borderColor: 'rgba(87,236,243,1)',
            color: 'rgba(87,236,243,1)',
            fontWeight: 'bold',
            borderRadius: '4px',
            fontSize: '0.875rem',
            '&:hover': {
              borderColor: 'orange',
              color: 'orange',
              backgroundColor: 'transparent'
            },
          }}
          onClick={toggleAbout}
        >
          About
        </Button>
      </div>
      <AboutModal isOpen={isAboutOpen} onClose={toggleAbout} />
    </div>
  );  
}

export default MainPageTemplateCustomLayout;




